import { NavLink } from 'react-router-dom';
import { styled } from 'react-ui-kit-exante';

export const StyledLink = styled(NavLink)(({ theme }) => ({
  color: theme?.color.typo?.action,
  cursor: 'pointer',
  textDecoration: 'underline',
  '&:hover': {
    color: theme?.color.typo?.promo,
  },
}));
