import {
  useGetCoreUserQuery,
  useGetMasterUsersQuery,
  useGetSubUsersQuery,
  useGetUserClientsQuery,
  useGetUserGroupSettingsQuery,
} from '~/api';
import { usePickUserPermissions } from '~/hooks';
import { IUsePageDataReturn } from '~/types/rtkQuery';

import { IDataAvailabilityState, IGeneralSettingsQueries } from './types';

const NOT_FOUND_USER = '#not_fount_user';

export const useGeneralSettingsData = (
  userId: number,
): IUsePageDataReturn<IGeneralSettingsQueries, IDataAvailabilityState> => {
  const userPermissions = usePickUserPermissions([
    'User permissions on services',
  ]);

  const doesntHavePermissionOnSubAnsMasterUsers =
    !userPermissions['User permissions on services'].read;

  const userQuery = useGetCoreUserQuery({ id: userId });

  const userName = userQuery?.data?.username || NOT_FOUND_USER;

  const userClientsQuery = useGetUserClientsQuery(
    { userName },
    { skip: userName === NOT_FOUND_USER },
  );
  const subUsersQuery = useGetSubUsersQuery(
    { userName },
    {
      skip:
        userName === NOT_FOUND_USER || doesntHavePermissionOnSubAnsMasterUsers,
    },
  );
  const masterUsersQuery = useGetMasterUsersQuery(
    { userName },
    {
      skip:
        userName === NOT_FOUND_USER || doesntHavePermissionOnSubAnsMasterUsers,
    },
  );
  const userGroupSettingsQuery = useGetUserGroupSettingsQuery(
    { userName },
    { skip: userName === NOT_FOUND_USER },
  );

  // isFetching is needed because we should rebuild form when navigate between users by navigation arrows
  const isUserLoading = userQuery.isLoading || userQuery.isFetching;
  const isUserClientsLoading =
    userClientsQuery.isLoading || userClientsQuery.isFetching;
  const isSubUsersLoading = subUsersQuery.isLoading || subUsersQuery.isFetching;
  const isMasterUsersLoading =
    masterUsersQuery.isLoading || masterUsersQuery.isFetching;
  const isGroupSettingsLoading =
    userGroupSettingsQuery.isLoading || userGroupSettingsQuery.isFetching;

  const isDataLoading =
    isUserLoading ||
    isUserClientsLoading ||
    isSubUsersLoading ||
    isMasterUsersLoading ||
    isGroupSettingsLoading;

  const hasGeneralSettingsData = Boolean(userQuery.data);
  const hasUserClientsData = Boolean(userClientsQuery.data);
  const hasSubUsersData = Boolean(subUsersQuery.data);
  const hasMasterUsersData = Boolean(masterUsersQuery.data);
  const hasGroupSettingsData = Boolean(userGroupSettingsQuery.data);

  return {
    queries: {
      userQuery,
      userClientsQuery,
      subUsersQuery,
      masterUsersQuery,
      userGroupSettingsQuery,
    },
    isDataLoading,
    dataAvailabilityState: {
      hasGeneralSettingsData,
      hasUserClientsData,
      hasSubUsersData,
      hasMasterUsersData,
      hasGroupSettingsData,
    },
  };
};
