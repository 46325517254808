import { pick } from 'lodash';

import { getValueFromOption } from '~/shared/utils';
import { IOption } from '~/types/form';
import {
  IMirroringRule,
  IMirroringRuleTagsRebateInfo,
  TMirroringRulePayload,
} from '~/types/mirroringRules';

import { expectedFields } from './constants';

function mirroringRuleMapper(mirroringRule: IMirroringRule): IMirroringRule {
  const mirroringRuleClone = { ...mirroringRule };
  const { tags } = mirroringRuleClone;
  const { transfer, rebate } = tags || {};

  if (transfer?.from && transfer?.to) {
    mirroringRuleClone.transferTag = `${transfer.from.legalEntity}:${transfer.to.legalEntity}`;
  }

  if (rebate?.from) {
    mirroringRuleClone.rebateTag = `${rebate.from.legalEntity || ''} ${
      rebate.from.account
    }`;
  }

  return pick(mirroringRuleClone, expectedFields);
}

export function mirroringRulesMappers(
  mirroringRules: IMirroringRule[],
): IMirroringRule[] {
  return mirroringRules.map(mirroringRuleMapper);
}

export const transformFormTargetToOriginal = (
  targetAccount: string | IOption,
): string => getValueFromOption(targetAccount);

export const transformFormRebateAccountToOriginal = ({
  account,
}: IMirroringRuleTagsRebateInfo): string =>
  account ? getValueFromOption(account) : '';

export const excludeTagsKeys = (mirroringRule: TMirroringRulePayload) => {
  const { tags } = mirroringRule;

  if (!tags) {
    return null;
  }

  const { transfer, rebate } = tags;

  if (transfer) {
    const { from, to } = transfer;

    if (
      !from.legalEntity ||
      from.legalEntity === 'null' ||
      !to.legalEntity ||
      to.legalEntity === 'null'
    ) {
      delete tags.transfer;
    }
  }

  if (rebate) {
    const { from } = rebate;
    from.account = transformFormRebateAccountToOriginal(from);
    const isAccountEmpty = !from.account || from.account === 'null';
    const isLegalEntityEmpty = !from.legalEntity || from.legalEntity === 'null';

    if (isAccountEmpty) {
      delete from.account;
    }

    if (isLegalEntityEmpty) {
      delete from.legalEntity;
    }

    if (isAccountEmpty && isLegalEntityEmpty) {
      delete tags.rebate;
    }
  }

  if (!Object.keys(tags).length) {
    return null;
  }

  return tags;
};
