import { pick } from 'lodash';

import { formatDateToYYYYMMDD } from '~/shared/utils';
import {
  ICoreFormUser,
  ICoreUser,
  ICoreUserInfo,
  IFormMasterUser,
  IFormSubUser,
  IFormUserClient,
  IMasterUser,
  IOriginatorIds,
  ISubUser,
  IUser,
  IUserClient,
  IUserFormOriginatorIds,
} from '~/types/users';

import { expectedFields } from './users.api.constants';

function userMapper(user: IUser): IUser {
  return pick(user, expectedFields);
}

export function usersMapper(users: IUser[]): IUser[] {
  return users.map((user) => userMapper(user));
}

export const originatorIdsMapper = (
  userInfo: ICoreUserInfo,
): IUserFormOriginatorIds => {
  const result = pick(userInfo, 'originatorIds');

  if (
    !result.originatorIds ||
    typeof result.originatorIds.overrides === 'undefined'
  ) {
    return { default: result.originatorIds?.default || '', overrides: [] };
  }

  return {
    default: result.originatorIds.default,
    overrides: Object.entries(result.originatorIds.overrides),
  };
};

const extractOriginatorIds = (
  originatorIds: IUserFormOriginatorIds,
): IOriginatorIds => {
  const result = { default: originatorIds.default } as IOriginatorIds;

  if (!originatorIds.default) {
    result.default = null;
  }

  if (originatorIds.overrides) {
    result.overrides = Object.fromEntries(originatorIds.overrides);
  }

  return result;
};

export const coreFormUserMapper = (user: ICoreUser): ICoreFormUser => {
  const prepareOriginatorIds = originatorIdsMapper(user.info);
  let notifications;

  try {
    notifications = JSON.stringify(user.info.notifications, null, 2);
  } catch (e) {
    notifications = user.info.notifications;
  }

  return {
    ...user,
    info: {
      ...user.info,
      notifications,
      originatorIds: prepareOriginatorIds,
      extraData: {
        ...user.info.extraData,
        internalInfo: user.info.extraData?.internalInfo || '',
      },
    },
  };
};

export const transformFormUserToCoreUser = (
  formUser: ICoreFormUser,
): ICoreUser => {
  const originatorIds = formUser.info.originatorIds
    ? extractOriginatorIds(formUser.info.originatorIds)
    : null;

  const preparedUserInfo = Object.entries(formUser.info).reduce<ICoreUserInfo>(
    (acc, item) => {
      const [name, value] = item as [keyof ICoreUserInfo, string | number];

      if (name === 'notifications' && typeof value !== 'number') {
        return {
          ...acc,
          [name]: value,
        };
      }

      if (value === undefined || value === null) {
        return acc;
      }

      if (name === 'birthDate' && value) {
        return {
          ...acc,
          [name]: formatDateToYYYYMMDD(new Date(value)),
        };
      }

      if (name === 'originatorIds' && originatorIds) {
        return {
          ...acc,
          [name]: originatorIds,
        };
      }

      if (name === 'email' && value === '') {
        return {
          ...acc,
          [name]: null,
        };
      }

      return {
        ...acc,
        [name]: value,
      };
    },
    {} as ICoreUserInfo,
  );

  return { ...formUser, info: preparedUserInfo };
};

export const transformFormUserClientsToOriginal = (
  userClients: IFormUserClient[],
): IUserClient[] =>
  userClients.map(({ clientId, userId, rights }) => {
    return {
      clientId: typeof clientId !== 'string' ? clientId.value : clientId,
      userId,
      rights,
    };
  });

export const transformFormSubUsersToOriginal = (
  subUsers: IFormSubUser[],
  userName = '',
): ISubUser[] =>
  subUsers.map(({ id, subUser, user }) => {
    return {
      subUser: typeof subUser !== 'string' ? subUser.value : subUser,
      user: user || userName,
      id,
    };
  });

export const transformFormMasterUsersToOriginal = (
  masterUsers: IFormMasterUser[],
  userName = '',
): IMasterUser[] =>
  masterUsers.map(({ id, subUser, user }) => {
    return {
      user: typeof user !== 'string' ? user.value : user,
      subUser: subUser || userName,
      id,
    };
  });
